// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-19abhot-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  /* bottom: 0; */
  top: 0;
  width: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #ec4899;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,MAAM;EACN,WAAW;EAEX,sDAAsD;EACtD,yBAAyB;AAC3B","sourcesContent":[".css-19abhot-MuiTabs-indicator {\n  position: absolute;\n  height: 2px;\n  /* bottom: 0; */\n  top: 0;\n  width: 100%;\n  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  background-color: #ec4899;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
