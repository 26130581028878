import { Container } from '@mui/material'
import React from 'react'

const TermsOfService = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 15,
        mb: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 2,
        boxShadow: 5,
      }}
    >
      <div
        style={{
          fontFamily: 'Arial, sans-serif',
          lineHeight: 1.6,
          backgroundColor: '#f9f9f9',
          padding: 15,
        }}
      >
        <h1
          style={{
            color: '#333',
            fontSize: '3em',
            marginBottom: 20,
            textAlign: 'center',
          }}
        >
          Terms of Service
        </h1>
        <p>
          <strong>
            Effective Date: <span> April 3, 2020</span>
          </strong>
        </p>
        <p>
          <strong>Last Updated:</strong>
          <span> September 24, 2024</span>
        </p>
        <p>
          Welcome to SaaSJournal, operated by SaaSJournal ("we," "us," "our").
          By accessing and using our website{' '}
          <a href="https://saasjournal.io">https://saasjournal.io</a> (the
          "Site"), you agree to comply with and be bound by these Terms of
          Service ("Terms"). If you do not agree to these Terms, you must not
          use the Site.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          1. Acceptance of Terms
        </h2>
        <p>
          By accessing or using any part of the Site, you agree to these Terms.
          We may modify the Terms from time to time, and your continued use of
          the Site will signify your acceptance of the updated Terms.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          2. Use of the Site
        </h2>
        <p>
          <strong>Eligibility:</strong> You must be at least 18 years old to use
          the Site. By using the Site, you represent and warrant that you have
          the legal capacity to enter into these Terms.
        </p>
        <p>
          <strong>Permitted Use:</strong> You may use the Site for personal or
          commercial purposes related to SaaS information, resources, and
          industry news. You agree not to use the Site for any unlawful purpose
          or to violate any applicable laws and regulations.
        </p>
        <p>
          <strong>Prohibited Use:</strong> You may not:
        </p>
        <ul style={{ paddingLeft: 35 }}>
          <li>
            Engage in unauthorized use of the Site, including attempting to gain
            unauthorized access to our servers or other systems.
          </li>
          <li>Use the Site to submit false or misleading information.</li>
          <li>
            Scrape, crawl, or otherwise extract data from the Site for
            unauthorized purposes.
          </li>
        </ul>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          3. Account Registration and Management
        </h2>
        <p>
          Some features of the Site, such as subscribing to newsletters or
          submitting content, may require you to create an account or provide
          personal information such as your name and email address. You agree
          to:
        </p>
        <ul style={{ paddingLeft: 35 }}>
          <li>Provide accurate and complete information.</li>
          <li>Keep your account information up-to-date.</li>
          <li>Not share your account credentials with others.</li>
        </ul>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          4. Submission of Content
        </h2>
        <p>
          By submitting press releases, comments, or other content to
          SaaSJournal, you grant us a worldwide, non-exclusive, royalty-free,
          perpetual license to use, reproduce, edit, publish, and distribute
          your content for any purpose related to our Site.
        </p>
        <p>
          You are solely responsible for ensuring that any content you submit:
        </p>
        <ul style={{ paddingLeft: 35 }}>
          <li>
            Does not infringe upon the intellectual property rights of others.
          </li>
          <li>Is accurate and free from harmful or unlawful material.</li>
        </ul>
        <p>
          We reserve the right to remove or refuse to post any content that
          violates these Terms.
        </p>

        <h2>5. Privacy Policy</h2>
        <p>
          We care about your privacy. Our Privacy Policy explains how we
          collect, use, and protect your personal data. By using the Site, you
          agree to the practices described in our Privacy Policy.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          6. Intellectual Property Rights
        </h2>
        <p>
          All content and materials available on the Site, including but not
          limited to text, graphics, logos, images, and software, are the
          intellectual property of SaaSJournal and are protected by copyright,
          trademark, and other intellectual property laws.
        </p>
        <p>
          <strong>Restrictions:</strong>
        </p>
        <ul style={{ paddingLeft: 35 }}>
          <li>
            You may not reproduce, distribute, modify, or create derivative
            works of any content without our explicit permission.
          </li>
          <li>
            You are granted a limited license to access and use the Site for
            your personal use only. This license does not include any resale of
            the Site’s contents.
          </li>
        </ul>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          7. Third-Party Links and Resources
        </h2>
        <p>
          The Site may contain links to third-party websites or services. These
          links are provided for convenience and informational purposes only,
          and we do not endorse, monitor, or control the content of such
          third-party websites. SaaSJournal is not responsible for any
          third-party content or websites, nor liable for any damages or losses
          caused by the use of or reliance on third-party content or services.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          8. Limitation of Liability
        </h2>
        <p>
          To the fullest extent permitted by applicable law, SaaSJournal and its
          officers, directors, employees, or agents shall not be liable for any
          indirect, incidental, special, or consequential damages arising from
          your use of the Site or from any content, products, or services made
          available through the Site.
        </p>
        <p>
          SaaSJournal provides the Site "as is" and "as available." We do not
          warrant that the Site will be uninterrupted, error-free, or free of
          viruses or other harmful components.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          9. Indemnification
        </h2>
        <p>
          You agree to indemnify and hold SaaSJournal, its officers, directors,
          employees, and agents harmless from any claims, damages, liabilities,
          costs, or expenses (including reasonable legal fees) arising out of
          your use of the Site, your violation of these Terms, or your violation
          of any third-party rights, including intellectual property or privacy
          rights.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          10. Termination
        </h2>
        <p>
          We reserve the right to terminate or suspend your access to the Site
          at any time, with or without notice, for any reason, including but not
          limited to a violation of these Terms.
        </p>
        <p>
          Upon termination, the provisions of these Terms regarding intellectual
          property rights, limitation of liability, indemnification, and any
          other provisions intended to survive termination will remain in
          effect.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          11. Governing Law and Dispute Resolution
        </h2>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of [Your Country/State], without regard to its conflict of law
          principles. Any disputes arising from these Terms shall be subject to
          the exclusive jurisdiction of the courts of [Your Country/State].
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          12. Changes to the Terms
        </h2>
        <p>
          We may revise these Terms from time to time. If we make material
          changes, we will provide notice by updating the "Last Updated" date at
          the top of the Terms. Your continued use of the Site after such
          changes will constitute your acceptance of the revised Terms.
        </p>

        <h2 style={{ color: '#333', fontSize: '1.5em', margin: '20px 0 10px' }}>
          13. Contact Us
        </h2>
        <p>
          If you have any questions or concerns regarding these Terms of
          Service, please contact us at:
        </p>
        <p>Email: info@saasjournal.io</p>
        <p>Address: Journal Group LLC, 400 N Ashley Dr Tampa FL 33602</p>
      </div>
    </Container>
  )
}

export default TermsOfService
