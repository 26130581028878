import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToTop = () => {
  const { pathname } = useLocation()

  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export default useScrollToTop
